@use 'resolutions' as *;

.section {
  min-height: calc(100vh - 60px);
}

.radius {
  border-radius: 0.5rem;
}

.sal {
  opacity: 0;
}

.font-alumni {
  font-family: 'Alumni Sans', sans-serif;
}

.about-me-anchor {
  scroll-margin-top: 550px;
}

.text-box {
  font-size: 5rem;
}

.mt-3-125 {
  margin-top: 3.125rem;
}

.padding-input {
  padding: 0.75rem 1rem;
}

.img-portrait {
  height: 180px;
  object-fit: cover;
  object-position: 0 20%;
}

.object-fit-cover {
  object-fit: cover;
}

.shadow-up {
  box-shadow: 40px -35px 22px 0px rgba(213, 202, 237, 0.6);
}

.shadow-down {
  box-shadow: -40px 30px 22px 0px rgba(213, 202, 237, 0.6);
}

@media (min-width: $sm) {
  .img-portrait {
    height: 400px;
  }
}

@media (min-width: $lg) {
  .section {
    min-height: calc(100vh - 100px);
  }

  .text-box {
    font-size: 5.625rem;
  }

  .pointer {
    cursor: pointer;
  }
}
