$font-sizes: 14, 18, 24, 36, 48, 54;
$font-weights: 200, 300, 400, 500, 600, 700, 800;

@each $size in $font-sizes {
  .font-#{$size} {
    font-size: $size + px;
  }
}

@each $weight in $font-weights {
  .font-#{$weight} {
    font-weight: $weight;
  }
}

.text-nowrap {
  white-space: nowrap;
}

.font-bold {
  font-weight: 700;
}
