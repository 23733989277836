@use 'src/assets/sass/custom-styles/abstracts/colors' as *;

.copy {
  color: $color-white;
}

footer li:hover a {
  text-decoration: underline;
}

.block.copy {
  padding: 1rem 0;
}

.img-logo {
  width: 91px;
  height: 88px;
  background-image: url('../../assets/images/logo-main.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.img-icon {
  width: 15px;
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;

  &.phone {
    background-image: url('../../assets/images/icon-phone.svg');
  }

  &.message {
    background-image: url('../../assets/images/icon-message.svg');
  }

  &.time {
    background-image: url('../../assets/images/icon-time.svg');
  }

  &.facebook {
    width: 25px;
    height: 25px;
    margin: 1rem auto 0;
    background-image: url('../../assets/images/icon-facebook.svg');
  }
}
