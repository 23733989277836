@use 'src/assets/sass/custom-styles/abstracts/colors' as *;
@use 'src/assets/sass/custom-styles/abstracts/resolutions' as *;

.menu {
  background-color: $color-basic2;
  position: relative;
  z-index: 999;

  &-bar {
    height: 60px;
  }

  &-burger {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: calc(60px - 1rem);
    padding: 0.5rem;
    cursor: pointer;

    span {
      display: block;
      position: relative;
      width: 100%;
      height: 3px;
      background-color: $color-basic-text;
      border-radius: 1rem;
      z-index: 99;
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        opacity 0.55s ease;
    }
  }

  &-list-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 60px;
    height: calc(100vh - 60px);
    background-color: $color-basic2;
    transform: translateX(-100%);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      opacity 0.55s ease;
    z-index: 99;
  }

  &-link {
    position: relative;
    padding: 0.25rem 0.75rem;
    border-radius: 0.5rem;

    &:hover,
    &.active {
      box-shadow: 0px 0px 1px 1px $color-basic-dark;
    }
  }
}

body.open .menu-list-wrapper {
  transform: translateX(0);
}

body.open {
  height: 100vh !important;

  span:nth-child(1) {
    transform: rotate(45deg) translate(9px);
  }

  span:nth-child(2) {
    transform: translateX(-9px);
    opacity: 0;
  }

  span:nth-child(3) {
    transform: rotate(-45deg) translate(9px);
  }
}

@media (min-width: $lg) {
  .menu {
    border-bottom: 1px solid darken(#ede7e1, 2%);

    &-bar {
      height: 100px;
    }

    &-link {
      min-width: 130px;
      font-size: 1rem;
      padding: 0;
      line-height: 2.2;
    }

    &-list-wrapper {
      position: static;
      transform: translateX(0);
      height: 100%;
    }
  }
}

@media (min-width: 1120px) {
  .menu-link {
    padding: 0 1rem;
  }
}
