$xs: 420px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1074px;
$xxl: 1200px;
$max: 2000px;

$resolutions: (
  'xs': $xs,
  'sm': $sm,
  'md': $md,
  'lg': $lg,
  'xl': $xl,
  'xxl': $xxl,
  'max': $max,
);

@each $resolution, $i in $resolutions {
  .container-width-#{$resolution} {
    max-width: #{$i} !important;
    margin: 0 auto !important;
  }
}
