@use 'src/assets/sass/custom-styles/abstracts/colors' as *;
@use 'src/assets/sass/custom-styles/abstracts/resolutions' as *;
@import url('https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@300;400;500;600;700&display=swap');

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 1rem;
  font-family: inherit;
  color: inherit;
  scroll-behavior: smooth;
}

* {
  border-radius: 0.125rem;

  &::-webkit-scrollbar {
    width: 0.35rem;
  }

  &::-webkit-scrollbar-thumb {
    width: 0.35rem;
    background-color: $color-basic-text;
  }
}

html {
  font-family: 'Darker Grotesque', sans-serif;
  font-size: 18px;
  color: $color-basic-text;
  scroll-behavior: smooth;
  min-height: -webkit-fill-available;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

.wrapper {
  padding-top: 60px;
  max-width: 100vw;
  overflow: hidden;
}

button {
  font-size: 1rem;
  background-color: transparent;
  border: none;
  border-radius: 0.5rem;
}

ul {
  list-style: none;
  padding-inline: unset;
}

a {
  text-decoration: none;
}

input,
textarea {
  border-radius: 0.5rem;
  font-family: inherit;
  border: none !important;
  outline: none;
}

fieldset {
  border: none;
}

@media (min-width: $lg) {
  .wrapper {
    //                 (menu height + footer height)
    min-height: calc(100vh - (321px));
    padding-top: 100px;
  }
}
