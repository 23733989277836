@use 'src/assets/sass/custom-styles' as *;

header {
  height: calc(100vh - 60px);
  background-color: $color-basic1-opacity;

  .header {
    &-img {
      height: 240px;
      object-fit: cover;
    }

    &-inner {
      padding: 3rem 1.5rem;
      font-size: 4rem;
      text-align: center;
    }
  }
}

.img-contact {
  top: 0;
  left: 0;
  right: 0;
  opacity: 0.2;
}

.copy {
  color: $color-basic2;
}

@media (min-width: $md) {
  .contact {
    margin-bottom: 14rem;
  }
}

@media (min-width: $lg) {
  header {
    height: calc(100vh - 100px);

    .header {
      &-img {
        width: 100%;
        height: calc(100vh - 140px);
        opacity: 1 !important;

        &-box {
          top: 0;
          right: 0;
          width: 40%;
        }
      }

      &-inner {
        display: flex;
        justify-content: center;

        padding: 0 6rem;
      }

      &-facebook {
        background-image: url('../images/icon-facebook.png');
        width: 25px;
        height: 25px;
        background-size: contai;
      }
    }
  }

  .img {
    &-contact {
      top: 20%;
      left: 20%;
      opacity: 1;
    }
  }

  .contact-form-wrapper {
    top: 25%;
    left: 15%;
    box-shadow: 17px 17px 17px 4px rgba($color-black, 0.3);
  }
}

@media (min-width: $xl) {
  header .header-img-box {
    width: 40%;
  }
}

@media (min-width: 1600px) {
  header .header-img-box {
    width: 60%;
  }
}
