@use 'src/assets/sass/custom-styles/abstracts/colors' as *;
@use 'src/assets/sass/custom-styles/abstracts/resolutions' as *;

input[type='checkbox'] {
  width: 25px;
  height: 25px;
  border-radius: 0.125rem;
  background-color: $color-basic-dark;
  color: $color-white;
  cursor: pointer;
}
