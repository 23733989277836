@use 'src/assets/sass/custom-styles/abstracts/colors' as *;
@use 'src/assets/sass/custom-styles/abstracts/typography';

button {
  @extend .font-700;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 250ms ease-in-out;
  cursor: pointer;
  font-weight: 700;
  line-height: 2.5;
  padding: 0 1rem;

  &.blue {
    background-color: $color-basic-dark;
    color: $color-white;
    border: 2px solid $color-basic-dark;

    &:hover {
      background-color: darken($color-basic-dark, 10%);
    }
  }

  &.red {
    background-color: $color-error;
    color: $color-basic2;
    border: 2px solid $color-error;

    &:hover {
      background-color: darken($color-error, 10%);
    }
  }

  &.white {
    background-color: $color-white;
    border: 2px solid $color-basic-dark;
    color: $color-basic-dark;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  &.gray {
    background-color: $color-basic2;
    border: 2px solid $color-basic-dark;

    &:hover {
      background-color: darken($color-basic2, 10%);
    }
  }
}
