$color-basic-dark: #111d71;
$color-basic-text: #22272f;
$color-black: #000000;
$color-gray: #a6aebf;
$color-basic1: #d5caed;
$color-basic2: #ede7e1;
$color-error: #e51a1a;
$color-success: #20b113;
$color-basic1-opacity: rgba(#d5caed, 0.2);
$color-basic2-opacity: rgba(#ede7e1, 0.3);
$color-white: #ffffff;

.bg-basic {
  background-color: $color-basic1;
}

.bg-basic-20 {
  background-color: rgba($color-basic1, 20%);
}

.bg-basic-2 {
  background-color: $color-basic2;
}

.bg-black {
  background-color: $color-black;
}

.bg-basic-opacity {
  background-color: $color-basic1-opacity;
}

.bg-gray {
  background-color: $color-gray;
}
