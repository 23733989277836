@use 'src/assets/sass/custom-styles/abstracts/colors' as *;
@use 'src/assets/sass/custom-styles/abstracts/resolutions' as *;

.cookies {
  width: 100%;
  position: fixed;
  bottom: -100%;
  left: 0;
  padding: 1.5rem 0;
  z-index: 2;
  background-color: $color-gray;
  transition: 1s;

  &.show {
    bottom: 0;
  }

  &-description {
    max-height: 150px;
    position: relative;
    z-index: 999;
  }
}

@media (min-width: $lg) {
  .cookies {
    padding: 4rem 0 6rem;
  }
}
